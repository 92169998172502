import { Injectable } from '@angular/core';
import { SecurityService, NotificationService } from './services';
import { flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(
        private securityService: SecurityService,
        private notificationService: NotificationService
    ) { }

    runReport(reportRequest) {
        return this.securityService.post('/sandbox/ws/report/run', reportRequest).pipe(
            flatMap(r => this.handleReportRequested(r))
        );
    }

    getReportType(reportTypeName) {
        return this.securityService.get("/sandbox/ws/report/type/" + reportTypeName);
    }

    public getClientContextFilters(reportType, clientContext) {
        return reportType.prompts
            .filter(p => p.clientContextProperty && clientContext[p.clientContextProperty])
            .map(p => {
                return { 
                    property: p.propertyName, 
                    operator: 'equal', 
                    values: clientContext[p.clientContextProperty]
                }
            });
    }

    public addClientContextFilters(reportRequest, reportType, clientContext) {
        let clientContextFilters = this.getClientContextFilters(reportType, clientContext);
        let filters;

        if (!reportRequest.filters) {
            filters = [];
        } else if (Array.isArray(reportRequest.filters)) {
            // clone the existing array so we don't change it
            filters = reportRequest.filters.slice(0);
        } else {
            // if the existing filters are not an array, then convert to the array form
            filters = [];
            for (let key in reportRequest.filters) {
                filters.push({ property: key, operator: 'equal', values: reportRequest.filters[key] });
            }
        }
        filters = [...filters, ...clientContextFilters];
        
        return Object.assign({}, reportRequest, { filters: filters });
    }

    handleReportRequested(response) {
        let reportRequestId = response.reportRequestId;
        console.log("REPORT REQUESTED", response);
        return this.notificationService.notify({
            type: 'reportRequestUpdated',
            'payload.reportRequestId': reportRequestId
        });
    }

}